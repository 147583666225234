import { NextPage } from 'next';
import { ExternalApp, useExternalAppLazyQuery } from '@/lib/generated/graphql';
import { useRouter } from 'next/router';
import { appsConfig } from '@/lib/config/apps';
import { useEffect, useState } from 'react';
import { buildAppUrl } from '@/lib/utils';
import { Text } from 'grommet';

const useGetAppId = (): string => {
  const { query } = useRouter();

  return (query?.id || '') as string;
};

/**
 * Retrieve the application configuration for rendering purposes.
 *
 * Because the view is shared between fixed apps and dynamic (backend-defined)
 * apps, the hook performs a lookup first on the fixed apps.
 *
 * If no fixed app is found, it fallbacks to query the backend.
 */
const useGetAppConfig = (): [ExternalApp | null, boolean] => {
  const appId = useGetAppId();
  const [config, setConfig] = useState<ExternalApp | null>(null);
  const [loading, setLoading] = useState(true);
  const [fetchFromBackend] = useExternalAppLazyQuery({
    variables: {
      id: appId,
    },
  });

  // On component mount, try to read the app config from fixed apps or query
  // from the backend using a lazy query.
  useEffect(() => {
    if (!appId) {
      return;
    }

    const appConfig = appsConfig.retool.apps?.[appId];

    if (appConfig) {
      setConfig({
        id: appId,
        name: appConfig.name,
        url: buildAppUrl('retool', appId),
      } as ExternalApp);
      setLoading(false);
      return;
    }

    fetchFromBackend()
      .then((res) => {
        setConfig((res.data?.externalApp ?? null) as ExternalApp | null);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [appId, fetchFromBackend]);

  return [config, loading];
};

const Page: NextPage = () => {
  const [config, loading] = useGetAppConfig();

  if (loading) {
    return <Text>Loading...</Text>;
  }

  if (!config) {
    return null;
  }

  return (
    <iframe
      referrerPolicy="origin"
      src={config?.url}
      style={{ border: 'none', height: '100%' }}
    />
  );
};

export default Page;
